const CellTypeEnum = {
    ALL: '',
    GLOMERULAR_ENDOTHELIUM: 'Glomerular Capillary Endothelium',
    GLOMERULAR: 'Glomerular Capillary Endothelial Cell',
    PARIETAL: 'Parietal Epithelial Cell',
    VISCERAL_EPITHELIUM: 'Glomerular Visceral Epithelium',
    VISCERAL: 'Visceral Epithelial Cell',
    GLOMERULAR_MESANGIUM: 'Glomerular Mesangium',
    MESANGIUM: 'Mesangial Cell',

    PROXIMAL_TUBULEL: 'Proximal Tubule',
    PROXIMAL_TUBULE_EITHELIAL: 'Proximal Tubule Epithelial Cell',
    PROXIMAL_TUBULE_EPITHELIAL_SEGMENT_1: 'Proximal Tubule Epithelial Cell Segment 1',
    PROXIMAL_TUBULE_EPITHELIAL_SEGMENT_2: 'Proximal Tubule Epithelial Cell Segment 2',
    PROXIMAL_TUBULE_EPITHELIAL_SEGMENT_3: 'Proximal Tubule Epithelial Cell Segment 3',

    LOOP_OF_HENLE_THIN: 'Loop of Henle (Thin Limb)',
    DESCENDING_THIN_LIMB: 'Descending Thin Limb Cell',
    DESCENDING_THIN_LIMB_TYPE_1: 'Descending Thin Limb Cell Type 1',
    DESCENDING_THIN_LIMB_TYPE_2: 'Descending Thin Limb Cell Type 2',
    DESCENDING_THIN_LIMB_TYPE_3: 'Descending Thin Limb Cell Type 3',
    ASCENDING_THIN_LIMB: 'Ascending Thin Limb Cell',

    DESCENDING_THIN_LIMB_CELL: 'Descending Thin Limb',
    DESCENDING_THIN_LIMB_CELL_TYPE_1: 'Descending Thin Limb Cell Type 1',
    DESCENDING_THIN_LIMB_CELL_TYPE_2: 'Descending Thin Limb Cell Type 2',
    DESCENDING_THIN_LIMB_CELL_TYPE_3: 'Descending Thin Limb Cell Type 3',
    ASCENDING_THIN_LIMB_CELL: 'Ascending Thin Limb Cell',

    LOOP_OF_HENLE_THICK: 'Loop of Henle (Thick Limb)',
    THICK_ASCENDING_LIMB_CELL: 'Thick Ascending Limb Cell',
    CORTICAL_THICK_ASCENDING_LIMB_CELL: 'Cortical Thick Ascending Limb Cell',
    MEDULLARY_THICK_ASCENDING_LIMB_CELL: 'Medullary Thick Ascending Limb Cell',

    DISTAL_CONVOLUTED: 'Distal Convolution',
    DISTAL_CONVOLUTED_TUBULE: 'Distal Convoluted Tubule Cell',
    DISTAL_CONVOLUTED_TUBULE_TYPE_1: 'Distal Convoluted Tubule Cell Type 1',
    DISTAL_CONVOLUTED_TUBULE_TYPE_2: 'Distal Convoluted Tubule Cell Type 2',
    MACULA_DENSA_CELL: 'Macula Densa Cell',


    TUBULES: 'Tubules',
    CONNECTING_TUBULES: 'Connecting Tubule',
    CONNECTING_TUBULE: 'Connecting Tubule Cell',
    CONNECTING_TUBULE_PRINCIPAL: 'Connecting Tubule Principal Cell',
    CONNECTING_TUBULE_INTERCALATED: 'Connecting Tubule Intercalated Cell',
    CONNECTING_TUBULE_INTERCALATED_TYPE_A: 'Connecting Tubule Intercalated Cell Type A',

    GLOM: 'Glomerulus / Renal Corpuscle',
    GLOMERULAR_PARIETAL_EPITHELIUM: 'Glomerular Parietal Epithelium',
    COLLECTING_DUCTS: 'Collecting Duct',
    PRINCIPAL_CELL: 'Principal Cell',
    CORTICAL_PRINCIPAL: 'Cortical Principal Cell',
    CORTICAL_COLLECTING_DUCT_PRINCIPAL: 'Cortical Collecting Duct Principal Cell',
    COLLECTING_DUCT: 'Collecting Duct Cell',
    COLLECTING_DUCT_PRINCIPAL: 'Collecting Duct Principal Cell',
    TRANSITIONAL_PRINCIPAL: 'Transitional Principal-Intercalated Cell',
    INTERCALATED: 'Intercalated Cell',
    INTERCALATED_TYPE_A: 'Intercalated Cell Type A',
    INTERCALATED_TYPE_B: 'Intercalated Cell Type B',
    CORTICAL_COLLECTING_DUCT_INTERCALATED_TYPE_A: 'Cortical Collecting Duct Intercalated Cell Type A',
    CORTICAL_COLLECTING_DUCT: 'Cortical Collecting Duct Cell',
    CORTICAL_INTERCALATED: 'Cortical Intercalated Cell',
    CORTICAL_INTERCALTED_TYPE_A: 'Cortical Intercalated Cell Type A',
    COLLECTING_DUCT_INTERCALATED: 'Collecting Duct Intercalated Cell',
    COLLECTING_DUCT_INTERCALATED_TYPE_A: 'Collecting Duct Intercalated Cell Type A',
    OUTER_MEDULLARY_COLLECTING_DUCT_INTERCALATED: 'Outer Medullary Collecting Duct Intercalated Cell',
    OUTER_MEDULLARY_COLLECTING_DUCT_INTERCALATED_TYPE_A: 'Outer Medullary Collecting Duct Intercalated Cell Type A',
    OUTER_MEDULLARY_INTERCALATED: 'Outer Medullary Intercalated Cell',
    OUTER_MEDULLARY_INTERCALATED_TYPE_A: 'Outer Medullary Intercalated Cell Type A',
    OUTER_MEDULLARY_COLLECTING_DUCT: 'Outer Medullary Collecting Duct Cell',
    OUTER_MEDULLARY_COLLECTING_DUCT_PRINCIPAL: 'Outer Medullary Collecting Duct Principal Cell',
    MEDULLARY_COLLECTING_DUCT_INTERCALATED: 'Medullary Collecting Duct Intercalated Cell',
    MEDULLARY_MEDULLARY_COLLECTING_DUCT_INTERCALATED_TYPE_A: 'Medullary Collecting Duct Intercalated Cell Type A',
    MEDULLARY_INTERCALATED: 'Medullary Intercalated Cell',
    MEDULLARY_INTERCALATED_TYPE_A: 'Medullary Intercalated Cell Type A',
    MEDULLARY_COLLECTING_DUCT: 'Medullary Collecting Duct Cell',
    MEDULLARY_COLLECTING_DUCT_PRINCIPAL: 'Medullary Collecting Duct Principal Cell',
    INNER_MEDULLARY_COLLECTING_DUCT: 'Inner Medullary Collecting Duct Cell',
    RENAL_CORPUSCLE: 'Renal Corpuscle',
    RENIN_GRANULES: 'Renin Granules',
    PARIETAL_BASEMENT_MEMBRANE: 'parietal_basement_membrane',
    GLOMERULAR_BASEMENT_MEMBRANE: 'glomerular_basement_membrane',


    VASCULAR_SMOOTH_MUSCLE_CELL: 'Vascular Smooth Muscle Cell',
    PERICYTE: 'Pericyte',
    FIBROBLAST: 'Fibroblast',
    MYOFIBROBLAST: 'Myofibroblast',
    MEDULLARY_FIBROBLAST: 'Medullary Fibroblast',
    JUXTAGLOMERULAR_GRANULAR_CELL: 'Juxtaglomerular Granular Cell',
    SCHWANN_NEURAL_CELL: 'Schwann / Neural Cell',


    IMMUNE: 'Immune',
    MONOCYTE: 'Monocyte',
    NON_CLASSICAL_MONOCYTE: 'Non-classical Monocyte',
    MONOCYTE_DERIVED_CELL: 'Monocyte-derived Cell',
    MONONUCLEAR_PHAGOCYTE: 'Mononuclear Phagocyte',
    M2_MACROPHAGE: 'M2-Macrophage',
    DENDRITIC_CELL: 'Dendritic Cell',
    CLASSICAL_DENDRITIC_CELL: 'Classical Dendritic Cell',
    PLASMACYTOID_DENDRITIC_CELL: 'Plasmacytoid Dendritic Cell',
    T_CELL: 'T Cell',
    CYTOTOXIC_T_CELL: 'Cytotoxic T Cell',
    REGULATORY_T_CELL: 'Regulatory T Cell',
    NATURAL_KILLER_CELL: 'Natural Killer Cell',
    NATURAL_KILLER_CELL_TYPE_1: 'Natural Killer Cell Type 1',
    NATURAL_KILLER_CELL_TYPE_2: 'Natural Killer Cell Type 2',
    NATURAL_KILLER_T_CELL: 'Natural Killer T Cell',
    B_CELL: 'B Cell',
    PLASMA_CELL: 'Plasma Cell',
    MAST_CELL: 'Mast Cell',
    NEUTROPHIL: 'Neutrophil',

    AFFERENT_ARTERIOLE_ENDOTHELIAL_CELL: 'Afferent Arteriole Endothelial Cell',
    EFFERENT_ARTERIOLE_ENDOTHELIAL_CELL: 'Efferent Arteriole Endothelial Cell',
    DESCENDING_VASA_RECTA_ENDOTHELIAL_CELL: 'Descending Vasa Recta Endothelial Cell',
    PERITUBULAR_CAPILLARY_ENDOTHELIAL_CELL: 'Peritubular Capillary Endothelial Cell',
    ASCENDING_VASCA_RECTA_ENDOTHELIAL_CELL: 'Ascending Vasa Recta Endothelial Cell',
    LYMPHATIC_ENDOTHELIAL_CELL: 'Lymphatic Endothelial Cell',
}

export default CellTypeEnum;
